import {
  Chip,
  Input,
  InputAdornment,
  ListSubheader,
  Paper,
  Tooltip,
} from "@mui/material";
//import styled from "styled-components";
import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  Card,
  CardContent,
  TextField,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BatchsourceTarget from "./BatchsourceTarget";
import {
  Name,
  Aligner,
  Value,
  Container,
  Title,
  ChartsDiv,
  MetricsDiv,
  Divider,
  Heading,
  Chips,
  TaskDetailsContainer,
  TaskDetails,
  BatchsourceTargetContainer,
} from "./JobViewStyles";
import Select from "react-select";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  userRequestDataBook,
  userRequestMartbuilder,
} from "../../../src/requestMethod";
import { useValue } from "../../../src/context/ContextProvider";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import Graph from "./Graph"; 

const buttonStyle = {
  border: "none",
  margin: "5px 10px",
  padding: "5px",
  cursor: "pointer",  
  backgroundColor: "#f0f0f0",  
  borderRadius: "5px",  
};

const backButtonStyle = {
  border: "none",
  fontSize:"22px",
  margin: "5px 10px",
  padding: "5px",
  cursor: "pointer",  
  backgroundColor: "#f0f0f0",  
  borderRadius: "5px",  
};

const BatchTaskView = ({ details, deleteTrigger }) => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const { getToken, token, error,getDatabookToken, DCtoken  } = useContext(AuthContext);
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("Details");

  const [editStates, setEditStates] = useState(details.map(() => false));

  // useState for storing graph data
  const [graphData, setGraphData] = useState();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState("");
  const [selectEnabled, setSelectEnabled] = useState(
    Array(details.length).fill(false)
  );

  const initializeSelectOptions = (keysObj) => {
    return Object.entries(keysObj).map(([key, label]) => ({
      value: key, label }));
  };
  
  // Initialize state with values from props
  const [accordionSelectedOptions, setAccordionSelectedOptions] = useState(
    details.map((detail) =>
      initializeSelectOptions(detail.srcPrimaryKeys || {})
    )
  );
  const [accordionSelectedOrderBy, setAccordionSelectedOrderBy] = useState(
    details.map((detail) =>
      initializeSelectOptions(detail.srcOrderByKeys || {})
    )
  );

  const [TableData, setTableData] = useState();
  const [columnsData, setColumnsData] = useState([]);
  const [BTVData, setBTVData] = useState({
    id: "",
    fqn: "",
    srcDatasetName: "",
    srcWatermarkColumn: "",
    srcPrimaryKeys: "",
    srcOrderByKeys: "",
    tgtAccountName: "",
    tgtContainerName: "",
    tgtDirectoryName: "",
    tgtFileFormat: "",
    ingestionMode: "",
    loadType: "",
    // sourceTargetColumnMapping: "",
    batchIngestionJob: {
      id: "",
    },
  });

  const handleEditClick = (index) => {
    const newEditStates = [...editStates];
    newEditStates[index] = !newEditStates[index];
    setEditStates(newEditStates);

    const newSelectEnabled = [...selectEnabled];
    newSelectEnabled[index] = !newSelectEnabled[index];
    setSelectEnabled(newSelectEnabled);
  };

  const {
    state: {
      showFolderTree,
      dataSources,
      dataBookToken,
      toggleEditButton,
      jobNameDetails,
    },
    dispatch,
  } = useValue();

  const columnsDataw = columnsData?.dataset?.columns.map((column) => {
    return {
      value: column.id,
      label: column.name,
    };
  });
  // put api
  //change the api end point 30nov
  const SendData = async (Payloaddata) => {
    console.log(Payloaddata, "final payload");
    dispatch({ type: "START_CIRCULAR_LOADING" });
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.put(
        `ingestion/batch/updateTask`,
        Payloaddata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      console.log(response);
      dispatch({ type: "END_CIRCULAR_LOADING" });
      if (response.status === 200) {
        alert("Batch Ingestion Job Updated Successfully");
        navigate("/");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const handleSubmit = (index) => {
    const task = details[index];

    const srcPrimaryKeys = accordionSelectedOptions[index].reduce(
      (acc, option) => {
        acc[option.value] = option.label;
        return acc;
      },
      {}
    );

    // Transform selected order by keys into the required format
    const srcOrderByKeys = accordionSelectedOrderBy[index].reduce(
      (acc, option) => {
        acc[option.value] = option.label;
        return acc;
      },
      {}
    );

    if (TableData === undefined) {
      alert("Please save table before submitting.");
    } else {
      const updatedData = {
        id: task.batchIngestionJob.id,
      };

      /*  const jsonData = JSON.stringify(updatedData, null, 2);
            console.log("PAYLOAD IS ====>",jsonData); */
      console.log(task, "<-----task");
      const FinalPayload = {
        // id: {id:task.batchIngestionJob.id},
        id: BTVData.id !== "" ? BTVData.id : task?.id,
        fqn: BTVData.fqn !== "" ? BTVData.fqn : task.fqn,
        srcDatasetName:
          BTVData.srcDatasetName !== ""
            ? BTVData.srcDatasetName
            : task.srcDatasetName,
        srcWatermarkColumn:
          BTVData.srcWatermarkColumn !== ""
            ? BTVData.srcWatermarkColumn
            : task.srcWatermarkColumn,
        srcPrimaryKeys: srcPrimaryKeys,
        srcOrderByKeys: srcOrderByKeys,

        tgtAccountName:
          BTVData.tgtAccountName !== ""
            ? BTVData.tgtAccountName
            : task.tgtAccountName,
        tgtContainerName:
          BTVData.tgtContainerName !== ""
            ? BTVData.tgtContainerName
            : task.tgtContainerName,
        tgtDirectoryName:
          BTVData.tgtDirectoryName !== ""
            ? BTVData.tgtDirectoryName
            : task.tgtDirectoryName,
        tgtFileFormat:
          BTVData.tgtFileFormat !== ""
            ? BTVData.tgtFileFormat
            : task.tgtFileFormat,
        ingestionMode:
          BTVData.ingestionMode !== ""
            ? BTVData.ingestionMode
            : task.ingestionMode,
        loadType: BTVData.loadType !== "" ? BTVData.loadType : task.loadType,
        sourceTargetColumnMapping: TableData.sourceTargetColumnMapping,

        batchIngestionJob:
          BTVData.batchIngestionJob.id !== ""
            ? BTVData.batchIngestionJob.id
            : updatedData,
        // batchIngestionTasks:[updatedData]
      };
      SendData(FinalPayload);
      console.log("Final Json payload ===>", JSON.stringify(FinalPayload));
    }
  };

  const [jobNameDetailValue, setJobNameDetailValue] = useState();
  const handleAccordionChange = (fqn) => {
    setSelectedOption("Details"); 
    setJobNameDetailValue(fqn) 
    setExpandedFqn(fqn === expandedFqn ? null : fqn);
  };

  const accordionStyle = {
    width: "100%",
    margin: "8px",
  };

  const [expandedFqn, setExpandedFqn] = useState(null);

  const handlePrimaryKeysChange = (selectedOptions, index) => {
    const updatedOptions = selectedOptions || [];
    const newAccordionSelectedOptions = [...accordionSelectedOptions];
    newAccordionSelectedOptions[index] = updatedOptions.map((option) => ({
      value: option.value,
      label: option.label,  
    }));
    setAccordionSelectedOptions(newAccordionSelectedOptions);
  };

  const handleOrderByKeysChange = (selectedOptions, index) => {
    const updatedOptions = selectedOptions || [];
    const newAccordionSelectedOrderBy = [...accordionSelectedOrderBy];
    newAccordionSelectedOrderBy[index] = updatedOptions.map((option) => ({
      value: option.value, // Ensure these match the structure of your options
      label: option.label,
    }));
    setAccordionSelectedOrderBy(newAccordionSelectedOrderBy);
  };

  const handlePayloadSubmit = (payloadData) => {
    // Handle the payload data here, for example, log it
    // console.log("Received payload data in BatchTaskView:", payloadData);
    setTableData(payloadData);
  };

  // Search function for dropdown
  const [searchPKText, setSearchPKText] = useState();
  const [searchOKText, setSearchOKText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };

  const fetchDatasets = async (node) => {
    const ourRequest = axios.CancelToken.source();
    // console.log("NODE:::::", node);
    try {
      let response = await userRequestDataBook.get(
        `/metainsights/datasets/${node}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${DCtoken}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // setColumnsData(oldVal => [...oldVal, response.data])
      setColumnsData(response.data);
      // console.log(response.data, "response.data ");
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    details.map((task) => fetchDatasets(task.srcDatasetName));
  }, []);

  //  console.log("deets", Object.values(details[1].srcPrimaryKeys))
  //  console.log("deets", details?.map(tasks=> tasks))
  // console.log("deets", columnsData)

  // DELETE API call for deleting the task from the batch ingestion
  const deleteBatchIngestionTask = async (row, batchingestionTaskId) => { 
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/ingestion/batch/task/${batchingestionTaskId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        alert("Task deleted successfully"); 
        deleteTrigger()
      } else {
        alert("Error");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else { 
        console.error(err);
      }
    }
  };

  const handleOptionClick = (option) => { 
    if (option === "Graph") {
      fetchGraphData(jobNameDetailValue, 30);
    }
    setSelectedOption(option);
  };

  useEffect(() => {}, [selectedOption]);

  const TableToggleStyle = {
    fontSize: "16px",
    cursor: "pointer",
    textUnderlineOffset: "10px",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    padding: "8px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  };
 
  const DetailsTab = {
    marginLeft: "15px",
    color: selectedOption === "Graph" ? "black" : "grey",
    backgroundColor:
      selectedOption === "Graph" ? "white" : "#f0f0f0",
    borderBottom:
      selectedOption === "Graph" ? "0.5px solid black" : "0.5px solid black",
  };

  const GraphTab = {
    marginLeft: "85px",
    backgroundColor: selectedOption === "Details" ? "white" : "#f0f0f0",
    color: selectedOption === "Details" ? "black" : "grey",
    borderBottom:
      selectedOption === "Details" ? "0.5px solid black" : "0.5px solid black",
  };

  //function for handling api call for graph
  const [days, setDays] = useState(30); 
  const fetchGraphData = async (jobNameDetailValue, days) => {
     
  const modifiedJobNameDetail = jobNameDetailValue.replace(/\*/g, "_").replace(/\-/g, "_").toLowerCase();

    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/metrics/getSparkMetrics/${modifiedJobNameDetail}/${days}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setGraphData(response.data); 
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };


  const [selectedButton, setSelectedButton] = useState(30); 

  const getButtonStyle = (days) => ({
    ...buttonStyle,
    border: selectedButton === days ? "2px solid pink" : "none",  
  });

  const handleFetchData = (days) => {
    console.log(days, "days<===graph")
    setDays(days);  
    fetchGraphData(jobNameDetailValue, days);
    setSelectedButton(days); 
  };

  return (
    <Container style={{ paddingInline: "1%" }}>
      <div
        style={{
          margin: "5px",
          color: "#247ba0",
          marginTop: "5px",
          fontWeight: "600",
        }}
      >
        <span>Task :</span>
      </div>

      <div style={{}}>
        {details.map((task, index) => (
          <Accordion
            style={accordionStyle}
            key={task.id}
            expanded={task.fqn === expandedFqn}
            onChange={() => handleAccordionChange(task.fqn)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={{ fontWeight: "bold" }}>{task.fqn}</Typography>
            </AccordionSummary>

            <AccordionDetails style={{   }}>
            {!toggleEditButton && (
              <Box style={{ padding: "9px" }}>
                <span
                  style={{ ...TableToggleStyle, ...GraphTab }}
                  onClick={() => handleOptionClick("Details")}
                >
                  Details
                </span>
                <span
                  style={{ ...TableToggleStyle, ...DetailsTab }}
                  onClick={() => handleOptionClick("Graph")}
                >
                  Task Metrics
                </span>
              <hr style={{position:"relative", bottom: "10px" }} />
              </Box>)}

              {toggleEditButton && (
                <Box
                  style={{
                    marginLeft: "28rem",
                    display: selectedOption === "Graph" ? "none" : "",
                  }}
                  className="button-box"
                >
                <Tooltip arrow placement="left"       
                     title="Click to Edit">
                      <Switch {...label}
                            style={{ cursor: "pointer" }}
                            fontSize="large"
                            onClick={() => handleEditClick(index)}
                        />
                    </Tooltip>

                  <button
                    onClick={() => deleteBatchIngestionTask(index, task?.id)}
                    className="edit-button"
                    style={{ marginLeft: "20px" }}
                  >
                    Delete
                  </button>
                  {editStates[index] ? (
                    <Box
                      style={{ marginLeft: "1rem", cursor: "pointer" }}
                      className="button-box"
                    >
                      <button
                        className="edit-button rounded-md job-btn-plain btn"
                        style={{ height: "40px" }}
                        onClick={() => handleSubmit(index)}
                        //  variant="contained"
                      >
                        Submit
                      </button>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: "1rem" }} className="button-box">
                      <button
                        // variant="contained"
                        style={{ height: "40px" }}
                        disabled
                        className="edit-button-Disabled rounded-md job-btn-plain btn"
                      >
                        Submit
                      </button>
                    </Box>
                  )}
                </Box>
              )}
              {selectedOption === "Details" && (
                <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TaskDetailsContainer>
                    <TaskDetails
                      //  style={{ maxHeight: '440px', overflowY: 'auto' }}
                      style={{ height: "auto", overflowY: "auto" }}
                    >
                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> FQN : </Name>
                        {/* <Value>
                          <span>{task.fqn}</span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={task.fqn}
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({ ...BTVData, fqn: e.target.value })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> ID : </Name>
                        {/* <Value>
                          <span>{task.id}</span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={task.id}
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({ ...BTVData, id: e.target.value })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Batch Ingestion Job ID : </Name>
                        {/* <Value>
                          <span>{task.batchIngestionJob.id}</span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={task.batchIngestionJob.id}
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              batchIngestionJob: { id: e.target.value },
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Source Dataset Name : </Name>
                        {/* <Value>
                          <span>{task.srcDatasetName}</span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={task.srcDatasetName}
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              srcDatasetName: e.target.value,
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name>Primary Keys :</Name>
                        <div style={{ width: "75%" }}>
                          <Select
                            isDisabled={!editStates[index]}
                            id="primary-keys"
                            placeholder={false}
                            aria-label="primary-keys"
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={columnsDataw}
                            onChange={(selectedOptions) =>
                                  handlePrimaryKeysChange(
                                    selectedOptions,
                                    index
                                  )
                                }
                                value={accordionSelectedOptions[index]}
                          ></Select>
                          {/* <Select
                                  isMulti
                                  options={Object.entries(task.srcPrimaryKeys).map(([id, value]) => ({
                                    value: id,
                                    label: value,
                                  }))}
                                  value={accordionSelectedOptions[index]}
                                  // value={task.srcPrimaryKeys ? Object.values(task.srcPrimaryKeys):""}
                                  onChange={(selectedValues) => handlePrimaryKeysChange(selectedValues, index)}
                                  isDisabled={!selectEnabled[index]}
                                /> */}
                          {/* <MUISelect
                          disabled={!editStates[index]}
                            fullWidth
                            size="small"
                            multiple
                            value={
                              task.srcPrimaryKeys
                                ? Object.values(task.srcPrimaryKeys)
                                : ""
                            }
                            onChange={(selectedValues) =>
                              handlePrimaryKeysChange(selectedValues, index)
                            }
                            onOpen={() => setSearchPKText("")}
                            onClose={() => setSearchPKText("")}
                            MenuProps={{
                              autoFocus: false,
                              PaperProps: {
                                style: {
                                  maxWidth: "72%", // Adjust the max width as needed
                                  maxHeight: "28rem",
                                },
                              },
                            }}
                          >
                            <ListSubheader sx={{ border: "none" }}>
                              <TextField
                                style={{ margin: "0 10px", width: "98%" }}
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder="Type to search..."
                                // fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) =>
                                  setSearchPKText(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </ListSubheader>
                            {displayedPKOptions !== "" &&
                              displayedPKOptions
                                ?.sort((a, b) =>
                                  a.name?.toLowerCase() > b.name?.toLowerCase()
                                    ? 1
                                    : -1
                                )
                                .map((item) => (
                                  <MenuItem key={item.id} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            {displayedPKOptions === "" &&
                              columnsData?.dataset?.columns
                                ?.sort((a, b) =>
                                  a.name?.toLowerCase() > b.name?.toLowerCase()
                                    ? 1
                                    : -1
                                )
                                .map((item) => (
                                  <MenuItem key={item.id} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            {/* {Object.entries(task.srcPrimaryKeys).map(
                              ([id, value]) => (
                                <MenuItem key={id} value={value}>
                                  {value}
                                </MenuItem>
                              )
                            )} */}
                          {/* </MUISelect>  */}
                        </div>
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name>OrderBy Keys :</Name>
                        <div style={{ width: "75%" }}>
                          <Select
                            id="primary-keys"
                            isDisabled={!editStates[index]}
                            placeholder={false}
                            aria-label="primary-keys"
                            options={columnsDataw}
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOptions) =>
                                  handleOrderByKeysChange(
                                    selectedOptions,
                                    index
                                  )
                                }
                                value={accordionSelectedOrderBy[index]}
                              ></Select>
                              {/* <Select
                                  isMulti
                                  options={Object.entries(task.srcOrderByKeys).map(([id, value]) => ({
                                    value: id,
                                    label: value,
                                  }))}
                                  value={accordionSelectedOrderBy[index]}
                                  // value={task.srcOrderByKeys ? Object.values(task.srcOrderByKeys): ""}
                                  onChange={(selectedValues) => handleOrderByKeysChange(selectedValues, index)}
                                  isDisabled={!selectEnabled[index]}
                                /> */}
                          {/* <MUISelect
                          disabled={!editStates[index]}
                            fullWidth
                            size="small"
                            multiple
                            value={
                              task.srcOrderByKeys
                                ? Object.values(task.srcOrderByKeys)
                                : ""
                            }
                            onChange={(selectedValues) =>
                              handleOrderByKeysChange(selectedValues, index)
                            }
                            onOpen={() => setSearchOKText("")}
                            onClose={() => setSearchOKText("")}
                            MenuProps={{
                              autoFocus: false,
                              PaperProps: {
                                style: {
                                  maxWidth: "72%", // Adjust the max width as needed
                                  maxHeight: "28rem",
                                },
                              },
                            }}
                          >
                            <ListSubheader sx={{ border: "none" }}>
                              <TextField
                                style={{ margin: "0 10px", width: "98%" }}
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder="Type to search..."
                                // fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) =>
                                  setSearchOKText(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </ListSubheader>
                            {displayedOKOptions !== "" &&
                              displayedOKOptions
                                ?.sort((a, b) =>
                                  a.name?.toLowerCase() > b.name?.toLowerCase()
                                    ? 1
                                    : -1
                                )
                                .map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    value={item.name}
                                    style={{ width: "98%" }}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                            {displayedOKOptions === "" &&
                              columnsData?.dataset?.columns
                                ?.sort((a, b) =>
                                  a.name?.toLowerCase() > b.name?.toLowerCase()
                                    ? 1
                                    : -1
                                )
                                .map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    value={item.name}
                                    style={{ width: "98%" }}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                            {/* {Object.entries(task.srcOrderByKeys).map(
                              ([id, value]) => (
                                <MenuItem key={id} value={value}>
                                  {value}
                                </MenuItem>
                              )
                            )} */}
                          {/* </MUISelect> */}
                        </div>
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name>Watermark Column : </Name>
                        {/* <Value>
                          <span>
                            {" "}
                            {task.srcWatermarkColumn ? (
                              task.srcWatermarkColumn
                            ) : (
                              <p>data unavailable</p>
                            )}
                          </span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={
                            task.srcWatermarkColumn
                              ? task.srcWatermarkColumn
                              : "data unavailable"
                          }
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              srcWatermarkColumn: e.target.value,
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Target Container Name : </Name>
                        {/* <Value>
                          <span>
                            {" "}
                            {task.tgtContainerName ? (
                              task.tgtContainerName
                            ) : (
                              <p>data unavailable</p>
                            )}
                          </span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={
                            task.tgtContainerName
                              ? task.tgtContainerName
                              : "data unavailable"
                          }
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              tgtContainerName: e.target.value,
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Target Account Name : </Name>
                        {/* <Value>
                          <span>
                            {" "}
                            {task.tgtAccountName ? (
                              task.tgtAccountName
                            ) : (
                              <p>data unavailable</p>
                            )}
                          </span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={
                            task.tgtAccountName
                              ? task.tgtAccountName
                              : "data unavailable"
                          }
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              tgtAccountName: e.target.value,
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Target Directory Name : </Name>
                        {/* <Value>
                          <span>
                            {" "}
                            {task.tgtDirectoryName ? (
                              task.tgtDirectoryName
                            ) : (
                              <p>data unavailable</p>
                            )}
                          </span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={
                            task.tgtDirectoryName
                              ? task.tgtDirectoryName
                              : "data unavailable"
                          }
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              tgtDirectoryName: e.target.value,
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Target File Format : </Name>
                        {/* <Value>
                          <span>{task.tgtFileFormat}</span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={task.tgtFileFormat}
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              tgtFileFormat: e.target.value,
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Load Type : </Name>
                        {/* <Value>
                          <span>{task.loadType}</span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={task.loadType}
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              loadType: e.target.value,
                            })
                          }
                        />
                      </Aligner>

                      <Aligner style={{ marginRight: "5px" }}>
                        <Name> Ingestion Mode : </Name>
                        {/* <Value>
                          <span>{task.ingestionMode}</span>
                        </Value> */}
                        <TextField
                          disabled = {!editStates[index]}
                          defaultValue={task.ingestionMode}
                          sx={{
                            padding: "5px",
                            borderRadius: "4px",
                            width: "75%",
                            minHeight: "25px",
                            overflow: "auto",
                            fontSize: "13.5px",
                          }}
                          size="small"
                          onChange={(e) =>
                            setBTVData({
                              ...BTVData,
                              ingestionMode: e.target.value,
                            })
                          }
                        />
                      </Aligner>
                    </TaskDetails>

                    <BatchsourceTargetContainer>
                      <div
                        style={{
                          margin: "5px",
                          marginLeft: "5px",
                          marginBottom: "10px",
                          color: "#247ba0",
                          fontWeight: "600",
                          marginTop: "0px",
                        }}
                      >
                        <span>Source Target Mapping : </span>
                      </div>
                      <BatchsourceTarget
                        data={task.sourceTargetColumnMapping}
                        onSavePayload={handlePayloadSubmit}
                      />
                    </BatchsourceTargetContainer>
                  </TaskDetailsContainer>
                </Grid>
              </Grid>
                </Box>
              )}
          {!toggleEditButton && (selectedOption === "Graph") && (
              <Box style={{paddingLeft:"150px"}}>
                <button style={getButtonStyle(1)} onClick={() => handleFetchData(1)}>1 day</button>
                <button style={getButtonStyle(7)} onClick={() => handleFetchData(7)}>1 week</button>
                <button style={getButtonStyle(30)} onClick={() => handleFetchData(30)}>1 month</button> 
                <button style={getButtonStyle(90)} onClick={() => handleFetchData(90)}>3 months</button>
                <button style={getButtonStyle(180)} onClick={() => handleFetchData(180)}>6 months</button>
                <button style={getButtonStyle(365)} onClick={() => handleFetchData(365)}>1 year</button>
              </Box>
              )}
                {selectedOption === "Graph" && (
                graphData && graphData.length > 0 ? (
                  graphData.map((dataItem, index) => (
                    <Graph 
                      key={index} 
                      metricsData={dataItem} 
                      fetchGraphData={fetchGraphData}
                      jobNameDetailValue={jobNameDetailValue} 
                      setDays={setDays}
                    />
                  ))
                ) : (
                  <div style={{paddingLeft:"150px", fontSize:"42px", fontWeight:"800",margin:"100px 0px"}} >
                    No data found  
                  </div>
                )
              )}

            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Container>
  );
};

export default BatchTaskView;
