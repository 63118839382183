// SideNav.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SideNavigationBar.css";
import Imageone from "./assets/imageone.png";
import Product from "./assets/Product.png";
import Approval from "./assets/Approval.png";
import Datasource from "./assets/Datasource.png";
import Authorization from "./assets/Authorization.png";
import Audit from "./assets/Audit.png";
import MetadataCrawl from "./assets/Metadata-Crawl.png";
import { Typography } from "@mui/material";
import smallDCLogo from "../../assets/images/smallLogo2.png";
import HomeLogo from "../../assets/sideNavBarLogo/assets/Screenshot 2024-05-14 at 6.20.20 PM.png";

const SideNavigationBar = () => {
  const [sideNavHover, setSideNavHover] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <nav
        className="nav__cont"
        onMouseEnter={() => setSideNavHover(true)}
        onMouseLeave={() => setSideNavHover(false)}
      >
        <ul className="nav">
          <li className="nav__items" onClick={() => navigate("/")}>
            <img src={HomeLogo} alt="" />
            <Typography className="Linktag">Home Page</Typography>
          </li>

          <li className="nav__items" onClick={() => navigate("/manageJobs")}>
            <img src={Imageone} alt="" />
            <Typography className="Linktag"> Manage Jobs </Typography>
          </li>

          <li className="nav__items" onClick={() => navigate("/jobRequest")}>
            <img src={Approval} alt="" />
            <Typography className="Linktag"> Job Requests </Typography>
          </li>

          <li
            className="nav__items"
            onClick={() => navigate("/manageProjects")}
          >
            <img src={Product} alt="" />
            <Typography className="Linktag"> Products </Typography>
          </li>

          <li
            className="nav__items"
            onClick={() => navigate("/manageDatasources")}
          >
            <img src={Datasource} alt="" />
            <Typography className="Linktag"> Datasources </Typography>
          </li>

          <li
            className="nav__items"
            onClick={() => navigate("/manageMetadatacrawls")}
          >
            <img src={MetadataCrawl} alt="" />
            <Typography className="Linktag"> MetadataCrawl </Typography>
          </li>

          <li
            className="nav__items"
            onClick={() => navigate("/manageAuthorization/applications")}
          >
            <img src={Authorization} alt="" />
            <Typography className="Linktag"> Authorization </Typography>
          </li>

          <li
            className="nav__items"
            onClick={() => navigate("/manageAuditLog")}
          >
            <img src={Audit} alt="" />
            <Typography className="Linktag"> Audit Log </Typography>
          </li>

          <div className="nav__items navItemsStyling">
            <span style={{ fontSize: "16px", color: "white" }}>
              Datasculpt V-1.0
            </span>
            {/* <img src={smallDCLogo} alt="smallDCLogo" /> */}
            {/* <Typography className="Linktag" style={{color:"white"}} >Datasculpt V-1.0</Typography> */}
          </div>
        </ul>
      </nav>
      {sideNavHover && <div className="backGblack"></div>}
    </>
  );
};

export default SideNavigationBar;
