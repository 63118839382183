import {
  AccountCircle,
  ArrowRight,
  Close,
  Dataset,
  Search,
  Storage,
} from "@mui/icons-material";
import React, { useEffect, useState, useContext } from "react";
import "./FolderTree.css";
import axios from "axios";
import {
  userRequestDataBook,
  userRequestMartbuilder,
} from "../../requestMethod";
import { IconButton, Input, InputAdornment, TextField } from "@mui/material";
import { useValue } from "../../context/ContextProvider";
import { AuthContext } from "../../AuthContext";
// import useDetectScroll, { Direction } from "@smakss/react-scroll-direction";

const FolderTree = ({ data, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchActive, setSearchActive] = useState(false);

  // const { scrollDir, scrollPosition } = useDetectScroll();

  const {
    state: { sourceConnectionBatch, sourceConnectionName, sourceConnectionFqn },
    dispatch,
  } = useValue();

  const { getToken, token, error, getDatabookToken, DCtoken } = useContext(AuthContext);

  //fetching the name of source connection
  const ourRequest = axios.CancelToken.source();
  const fetchSourceConnectionName = async () => {
    try {
      let response = await userRequestMartbuilder.get(
        `/datasources/${sourceConnectionBatch}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      //console.log(response?.data?.connectionFqn, "response.data.connectionFqn");
      dispatch({
        type: "SET_SOURCE_CONNECTION_FQN",
        payload: response.data.connectionFqn,
      });
      dispatch({
        type: "SET_SOURCE_CONNECTION_NAME",
        payload: response.data.connectionName,
      });
      // setIsLoading(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchSourceConnectionName();
    };

    fetchData();
  }, [sourceConnectionBatch]);

  // useEffect(() => {
  //   fetchSourceConnectionName();
  // }, [sourceConnectionBatch]);

  const toggleSearchField = () => {
    setSearchActive(!searchActive);
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredData = data?.filter((item) =>
    item?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div
      className="foldertree-main"
      // style={{
      // height: "100%",
      // fontSize: "1.1rem",
      // position: "relative",
      // padding: 0,
      // }}
    >
      {searchActive ? (
        <div
          className="foldertree-ip"
          // style={{
          //   width: "95%",
          //   fontSize: "14px",
          //   display: "flex",
          //   justifyContent: "space-between",
          //   alignItems: "center",
          //   marginLeft: "12px",
          //   position: "absolute",
          //   top: "0",
          //   left: "0",
          //   background: "#fff",
          // }}
        >
          <Input
            id="search"
            placeholder="Search Data Source"
            variant="standard"
            value={searchTerm}
            fullWidth
            onChange={handleSearch}
            sx={{ fontSize: "13px" }}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <Close onClick={toggleSearchField} sx={{ cursor: "pointer" }} />
              </InputAdornment>
            }
          />
        </div>
      ) : (
        <div
          className="foldertree-search"
          // style={{
          //   width: "95%",
          //   fontSize: "14px",
          //   display: "flex",
          //   justifyContent: "space-between",
          //   alignItems: "center",
          //   paddingLeft: "12px",
          //   position: "absolute",
          //   top: "0",
          //   left: "0",
          //   background: "#fff",
          // }}
        >
          <h3 className="small-h3">Data Source</h3>

          <Search
            color="disabled"
            onClick={toggleSearchField}
            sx={{ cursor: "pointer" }}
          />
        </div>
      )}

      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "24px",
          height: "76vh",
          // overflow: "scroll",
        }}
        className="d-tree-container"
      >
       {/* {console.log(scrollPosition.bottom== -0.5 && "Reached the end")} */}
        {/* { filteredData &&
          filteredData?.map((dataSource, index) => (
            <TreeNode node={dataSource} key={index} file={false} />
          )) } */}
        {/* <TreeNode node={sourceConnectionName || "Dataset"} file={false} /> */}
        {sourceConnectionName ? (
          <TreeNode node={sourceConnectionName} file={false} />
        ) : (
          filteredData &&
          filteredData.map((dataSource, index) => (
              <TreeNode node={dataSource} key={index} file={false} />
          ))
        )}
      </ul>
    </div>
  );
};

const FileTree = ({ data, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [activeItem, setActiveItem] = useState(null)
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter((item) =>
    item?.fqn.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div
      className="filetree-ip"
      // style={{
      //   // height: "100%",
      //   fontSize: "1.1rem",
      //   overflow: "scroll",
      //   padding: 0,
      //   paddingLeft: "2px",
      // }}
    >
      {/* <input
        type="text"
        placeholder="Search Datasets"
        value={searchTerm}
        onChange={handleSearch}
        style={{ width: "200px", marginLeft: "20px" }}
      /> */}
      <Input
        id="searchDatasets"
        placeholder="Search Datasets"
        variant="standard"
        value={searchTerm}
        fullWidth
        onChange={handleSearch}
        sx={{ fontSize: "13px" }}
        size="small"
        // endAdornment={
        //   <InputAdornment position="end">
        //     <Close onClick={toggleSearchField} sx={{ cursor: "pointer" }} />
        //   </InputAdornment>
        // }
      />
      <ul
        style={{ display: "flex", flexDirection: "column", minWidth: "250px" }}
        className="d-tree-container"
      >
        {filteredData &&
          filteredData?.map((dataSource, index) => (
            <TreeNodeChild node={dataSource} key={index} />
          ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, file }) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [apicallState, setApiCallState] = useState(false);
  const {
    state: { dataBookToken, sourceConnectionName, sourceConnectionFqn },
  } = useValue();

  // FETCHING DATASOURCE > DATASETS
  //change
  // apicall
  //api call for batch ingestion job
  const { getToken, token, error, getDatabookToken, DCtoken } = useContext(AuthContext);
  const ourRequest = axios.CancelToken.source();
  // setIsLoading(true)
  const fetchDatasets = async (schema) => {
    // console.log(schema, "schema");
    //console.log(sourceConnectionName, "sourceConnectionName");
    try {
      let response = await userRequestDataBook.post(
        `/metainsights/search`,

        {
          // pageIndex: 0,
          // pageSize: 2200,
          // schema: [schema],
          datasources: [sourceConnectionFqn],
        },
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${DCtoken}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      //console.log(response.data, "neeeew");
      setDatasets(response.data.results);
      setPageSize(response?.data?.totalRecords);
      // setIsLoading(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
        alert(err.message)
      }
    }
  };

  //api second call for batch ingestion job
  const fetchWithDataSources = async () => {
    // console.log(sourceConnectionName, "sourceConnectionName")
    try {
      let response = await userRequestDataBook.post(
        `/metainsights/search`,

        {
          pageIndex: 0,
          pageSize: 9999,
          datasources: [sourceConnectionFqn],
        },
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${DCtoken}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      //console.log(response.data, "neeeew 222");
      setDatasets(response.data.results);
      // setIsLoading(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  //for mart builder folder tree
  const fetchMartBuilderDatasets = async (schema) => {
    console.log(schema, "schema fetchMartBuilderDatasets");
    try {
      let response = await userRequestDataBook.post(
        `/metainsights/search`,

        {
          pageIndex: 0,
          pageSize: 2200,
          schemas: [schema],
        },
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${DCtoken}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      //console.log(response.data, "neeeew");
      setDatasets(response.data.results);
      // setIsLoading(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (pageSize && sourceConnectionFqn) {
        await fetchWithDataSources(node);
      }
    };
    fetchData();
  }, [pageSize, node, apicallState]);

  // useEffect(()=>{
  //   fetchSourceConnectionName()
  //   console.log("use seffect run")
  // },[])

  const handleNodeClick = (schema) => {
    // handleNodeClick 1
    //console.log("handleNodeClick1 CALLED");
    //console.log(schema, "handleNodeClick1");
    setApiCallState(!apicallState);
    if (!sourceConnectionFqn && !sourceConnectionName) {
      //console.log("martbuilder 000")
      fetchMartBuilderDatasets(schema);
    } else {
      fetchDatasets(schema);
    }
    setChildVisiblity((v) => !v);
  };

  // const hasChild = node?.children ? true : false
  const hasChild = true;

  return (
    <li
      className="d-tree-node"
      // style={{ border: "none" }}
      style={{ border: "1px solid grey" }}
      key={node}
    >
      <div
        style={{
          display: "flex",
          padding: 0,
          paddingLeft: "4px",
          cursor: "pointer",
        }}
        className={`d-tree-toggler ${childVisible ? "active" : ""}`}
        onClick={() => handleNodeClick(node)}
      >
        {/* {hasChild && (
          <div
            style={{ display: "inline", cursor: "pointer" }}
            className={`d-tree-toggler ${childVisible ? "active" : ""}`}
          >
            <ArrowRight color="action" onClick={() => handleNodeClick(node)} />
          </div>
        )} */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={`d-tree-head ${hasChild ? "" : "d-tree-end"}`}
        >
          <div style={{ display: "inline", marginRight: "4px" }}>
            <Storage fontSize="15px" color="info" />
          </div>
          <p
            style={{
              display: "inline",
              fontSize: "13px",
            }}
          >
            {node}
          </p>
        </div>
      </div>

      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul
            className="d-tree-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FileTree data={datasets} />
          </ul>
        </div>
      )}
    </li>
  );
};

const TreeNodeChild = ({ node, file }) => {
  const [datasets, setDatasets] = useState([]);
  const {
    state: { dataBookToken },
  } = useValue();
  // const [childVisible, setChildVisiblity] = useState(false)
  // const [activeItem, setActiveItem] = useState(null)
  const {
    dispatch,
    state: { dataSourceTable },
  } = useValue();

  // FETCHING DATASOURCE > DATASETS
  //apicall
  const { getToken, token, error , getDatabookToken, DCtoken} = useContext(AuthContext);
  const ourRequest = axios.CancelToken.source();
  // setIsLoading(true)
  const fetchDatasets = async (node) => {
    console.log(node, "NODE");
    try {
      let response = await userRequestDataBook.get(
        `/metainsights/datasets/${node}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${DCtoken}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      //console.log(response.data, "response.data ");
      dispatch({ type: "SET_DATASET_DETAILS", payload: response.data });
      // setIsLoading(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
        if (err.response && err.response.status === 404) { 
          alert("No Records found! Please verify the dataset chosen (404)");
        } else { 
          alert("An error occurred! Please try again later.");
        }
      }
    }
  };

  const handleNodeClick = (node) => {
    // handleNodeClick 2
    //console.log("handleNodeClick 2");
    dispatch({ type: "CLEAR_DATASET_DETAILS" });
    fetchDatasets(node);
    dispatch({ type: "SET_ACTIVE_DATASOURCE_TABLE", payload: node });
  };

  // const hasChild = node?.children ? true : false
  // const hasChild = true

  return (
    <li className="d-tree-node" style={{ border: "none" }} key={node?.fqn}>
      <div
        style={{
          display: "flex",
          padding: 0.2,
          paddingLeft: "4px",
          cursor: "pointer",
          width: "fit-content",
          // width: "100%",
        }}
        className={`d-tree-node-toggler ${
          dataSourceTable === node.fqn ? "active" : ""
        }`}
        onClick={() => {
          console.log("nodeeeee:", node);
          handleNodeClick(node.fqn);
        }}
      >
        {/* {hasChild && ( 
          <div
            style={{ display: "inline", cursor: "pointer" }}
            className={`d-tree-toggler ${childVisible ? "active" : ""}`}
          >
            <ArrowRight color="action" onClick={() => handleNodeCLick(node)} />
          </div>
        )} */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          // className={`d-tree-head ${hasChild ? "" : "d-tree-end"}`}
        >
          <div
            style={{ display: "inline", marginRight: "4px", fontSize: "13px" }}
          >
            <Dataset fontSize="small" color="secondary" />
          </div>
          <p style={{ display: "inline", fontSize: "12px" }}>{node?.fqn}</p>
        </div>
      </div>

      {/* {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul
            className="d-tree-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FileTree data={datasets} />
          </ul>
        </div>
      )} */}
    </li>
  );
};

export default FolderTree;
