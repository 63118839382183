// import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const i = isIE + isEdge + isFirefox;
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: "eccd4653-2930-4077-be40-8e73fd4855e6",
    authority:
      "https://login.microsoftonline.com/b02b023f-1855-427a-8002-288a6252bf79",
    redirectUri: "/",
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },

  /*  system: {
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //       default:
    //         return;
    //     }
    //   },
    // },
    // iframeHashTimeout: 10000,
  }, */
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const resourceUri = "https://datasculpt.gleafink.com/";
// const resourceScope = "api://bd384c6c-d925-4099-a56f-83567cdb7ab7/Users.RW";
const resourceScope = "api://eccd4653-2930-4077-be40-8e73fd4855e6/.default";
const databookResourceScope =
  "api://eccd4653-2930-4077-be40-8e73fd4855e6/.default";

export const loginRequest = {
  scopes: [],
};
export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  datacraft: {
    endpoint: resourceUri,
    scopes: [resourceScope],
  },
  databook: {
    endpoint: resourceUri,
    scopes: [databookResourceScope],
  },
};
