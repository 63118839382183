import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import logoImage from "../assets/images/logo.jpg";
// import photoUrl from "../profile.jpg"
import UserIcons from "./user/UserIcons";
import { Link } from "react-router-dom";
import newLogo from "../assets/images/newLogo1.png";

import { useIsAuthenticated } from "@azure/msal-react";
import { AuthContext } from "../AuthContext";

const Navbar = () => {
  const { getToken, token, error, getDatabookToken, DCtoken } =
    useContext(AuthContext);
  const isAuthenticated = useIsAuthenticated();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSaveToLocalStorage = () => {
    // Check if inputValue is not empty
    if (inputValue.trim() !== "") {
      // console.log(inputValue)
      localStorage.setItem("userToken", inputValue);
      alert("Token saved to localStorage");
    } else {
      alert("Please enter a valid token before saving.");
    }
    setInputValue("");
  };

  useEffect(() => {
    if (!DCtoken || !token) {
      getDatabookToken();
      getToken();
    }
  }, [DCtoken, token]);

  return (
    <AppBar sx={{ backgroundColor: "#002133" }} elevation={1}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Box sx={{ mr: 1 }}>
            <IconButton size="lg" color="inherit">
              <Menu />
            </IconButton>
          </Box> */}

          <Typography
            variant="h6"
            component="h1"
            noWrap
            sx={{
              flexGrow: 1,
              display: { display: "flex" },
            }}
          >
            <Link to="/" style={{ margin: 0, padding: 0, height: "35px" }}>
              <span style={{ fontSize: "25px", color: "white" }}>
                Datasculpt
              </span>
              {/* <img src={newLogo} height="35px" alt="Datacraft logo" style={{borderRadius:"2px"}} /> */}
            </Link>
          </Typography>
          {/* <div style={{ marginRight: "100px" }}>
            <input
              type="text"
              style={{ width: "200px" }}
              value={inputValue}
              onChange={handleInputChange}
              placeholder="EMP Token"
            />
            <Button
              variant="outlined"
              size="small"
              sx={{
                color: "#247ba0",
                borderColor: "#247ba0",
                fontSize: "9px",
                marginLeft: "10px"
              }}
              onClick={handleSaveToLocalStorage}
            >
              Submit Token
            </Button>
          </div> */}
          {/* <Typography
            variant="h6"
            component="h1"
            noWrap
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            MB
          </Typography> */}
          {isAuthenticated && <UserIcons />}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
