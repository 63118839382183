import { useState, useContext, useEffect, useMemo } from "react";
// import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Chip,
  InputAdornment,
  InputLabel,
  ListSubheader,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl.css";
import { useNavigate } from "react-router";
import { Input } from "@mui/base";

import UserDetailsModal from "./UserDetailsModal";

function AddNewRoleToUser({ boolean, onOpen, tableType }) {
  // function AddNewRole({ boolean, onOpen, tableType }) {
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [appsArray, setAppsArray] = useState([]);
  const [resourcesArray, setResourcesArray] = useState([]);
  const [operasArray, setOperasArray] = useState([]);
  const [domainsArray, setDomainsArray] = useState([]);
  // const [permissionsValue, setPermissionsValue] = useState("");
  const [emailID, setEmailID] = useState("");
  const [emailName, setEmailName] = useState();
  const [roleID, setRoleID] = useState();
  const [roleName, setRoleName] = useState();
  const [userData, setUserData] = useState();
  const [roleData, setRoleData] = useState();
  const [orgModelData, setOrgModelData] = useState();
  const [domainData, setDomainData] = useState();

  const [updModal, setUPDModal] = useState(false);

  const [values, setValues] = useState({
    email_id: "",
    // description: "",
  });

  const [policyFields, setPolicyFields] = useState([
    {
      // applications: "",
      // resource: [],
      // permissions: "",
      // operations: [],
      domains: [],
      domainfqn: [],
      byMaerskModel: [],
      byOrgModelsfqn: [],
      role: [],
    },
  ]);

  const handlePolicyChange = (event, index, target) => {
    // let data = [...policyFields];
    // data[index][event.target.name] = event.target.value;
    // setPolicyFields(data);
    // console.log(policyFields);

    if (target === "domains") {
      // console.log(event.target)
      let data = [...policyFields];
      data[index][target] = [];
      event.target.value.map((e) => {
        data[index][target].push(e);
      });
      setPolicyFields(data);
      // console.log(policyFields);
    }

    if (target === "byMaerskModel") {
      // console.log(event)
      let data = [...policyFields];
      data[index][target] = [];
      event.target.value.map((e) => {
        data[index][target].push(e);
      });
      setPolicyFields(data);
      // console.log(policyFields);
    }

    if (target === "role") {
      let data = [...policyFields];
      data[index][target] = [];
      data[index][target].push(event.target.value);
      setPolicyFields(data);
      // console.log(policyFields);
    }
  };

  const addPolicy = () => {
    let object = {
      // applications: "",
      // resource: [],
      // permissions: "",
      // operations: [],
      domains: [],
      byMaerskModel: [],
      role: [],
    };

    setPolicyFields([...policyFields, object]);
  };

  const removePolicy = (index) => {
    let data = [...policyFields];
    data.splice(index, 1);
    setPolicyFields(data);
  };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (permissionsValue.trim() !== "") {
  //     setPermissionsArray((prevPermissionsArray) => [
  //       ...prevPermissionsArray,
  //       permissionsValue,
  //     ]);
  //     setPermissionsValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updatePermissions = permissionsArray.filter((_, i) => i !== index);
  //   setPermissionsArray(updatePermissions);
  // };

  // Search function for dropdown
  const [searchUDText, setSearchUDText] = useState();
  const [searchRDText, setSearchRDText] = useState();
  const [searchODText, setSearchODText] = useState();
  const [searchDDText, setSearchDDText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };
  let displayedUDOptions = useMemo(
    () =>
      userData?.filter((option) => containsText(option.emailId, searchUDText)),
    [searchUDText]
  );
  let displayedRDOptions = useMemo(
    () => roleData?.filter((option) => containsText(option.name, searchRDText)),
    [searchRDText]
  );
  let displayedODOptions = useMemo(
    () =>
      orgModelData?.filter((option) => containsText(option.name, searchODText)),
    [searchODText]
  );
  let displayedDDOptions = useMemo(
    () =>
      domainData?.filter((option) => containsText(option.name, searchDDText)),
    [searchDDText]
  );

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);

  // Fetching values for users dropdown
  const fetchUsersData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatusers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setUserData(response?.data);
      console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const fetchRolesData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatroles`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setRoleData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const fetchOrgModelData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/orgmodel`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setOrgModelData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const fetchDomainData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/domains`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setDomainData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //api call for adding new data
  const AddNewRoleToUserData = async () => {
    const payload = policyFields.map((policy) => ({
      userId: emailID,
      // description: values.description,
      // permissions: policyFields.permissions,
      // applications: policyFields.applications,
      // resource: policyFields.resource,
      // operations: policyFields.operations,
      domains: policy.domains,
      byOrgModels: policy.byMaerskModel,
      roleIds: policy.role,
    }));
    console.log("Payload", payload);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.post(`/user_role`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const updatedValues = { ...values, [name]: value };
  //   setValues(updatedValues);
  // };

  const handleEmailChange = (event) => {
    setEmailID(event.target.value);
    setEmailName(event.target.name);
  };

  // const handleRoleChange = (event) => {
  //   console.log(event.target)
  //   setRoleID(event.target.value);
  //   setRoleName(event.target.name);
  // };

  const handleSubmit = async () => {
    // if (!values.email_id.trim()) {
    //   setShowEmptyFieldMessage(true);
    //   setTimeout(() => {
    //     setShowEmptyFieldMessage(false);
    //   }, 2000);
    //   return;
    // }
    await AddNewRoleToUserData();
    onOpen();
    // navigate("/manageAuthorization");
  };

  const navigate = useNavigate();

  useEffect(() => {
    (async () => await getToken())();
    fetchUsersData();
    fetchRolesData();
    fetchOrgModelData();
    fetchDomainData();
  }, [token]);

  return (
    // <div style={{ height: "100%", paddingTop: "60px" }}>
    //   <div
    //     style={{
    //       padding: "80px 20px 20px",
    //       // maxWidth: "700px",
    //       maxWidth: "94%",
    //       margin: "0px auto",
    //       border: "1px solid lightgray",
    //       borderRadius: "4px",
    //       backgroundColor: "#ffffff",
    //       boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
    //       // transition: "0.3s",
    //     }}
    //   >
    //     <div style={{}}>
    //       <Grid container mb={3}>
    //         <Grid item xs={11}>
    //           <Typography variant="h4" component="h4">
    //             Add Role Details
    //           </Typography>
    //         </Grid>

    //         <Grid color="text.secondary" item xs={1}>
    //           <CloseIcon
    //             onClick={() => navigate("/manageAuthorization")}
    //             sx={{
    //               cursor: "pointer",
    //             }}
    //           />
    //         </Grid>
    //       </Grid>
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           flex: 1,
    //           color: "black !important",
    //           fontWeight: 600,
    //         }}
    //       >
    //         Role Name
    //       </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Enter name"
    //         name="name"
    //         value={values.name}
    //         onChange={handleChange}
    //         style={{ width: "71.5%" }}
    //       />
    //     </div>

    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         margin: "20px 0px",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           flex: 1,
    //           color: "black !important",
    //           fontWeight: 600,
    //         }}
    //       >
    //         Role Description
    //       </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Enter Description"
    //         name="name"
    //         value={values.name}
    //         onChange={handleChange}
    //         style={{ width: "71.5%" }}
    //       />
    //     </div>
    //     {policyFields.map((policy, index) => {
    //       return (
    //         <div
    //           key={index}
    //           style={{
    //             // maxWidth:"90%",
    //             margin: "20px 0px",
    //             padding: "28px 16px",
    //             border: "2px solid lightgray",
    //             borderRadius: "4px",
    //             backgroundColor: "#ffffff",
    //             // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    //             // transition: "0.3s",
    //           }}
    //         >
    //           <div
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               margin: "20px 0px",
    //             }}
    //           >
    //             <Typography
    //               style={{
    //                 flex: 1,
    //                 color: "black !important",
    //                 fontWeight: 600,
    //               }}
    //             >
    //               Policy {index + 1}
    //             </Typography>
    //             <div
    //               style={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //                 // maxWidth: "335px",
    //                 // maxHeight: "80px",
    //               }}
    //             >
    //               <CancelIcon onClick={() => removePolicy(index)} />
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               display: "grid",
    //               gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    //               gap: "1rem",
    //             }}
    //           >
    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="application">Application</label>
    //               <Select
    //                 id="application"
    //                 placeholder={false}
    //                 aria-label="application"
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].applications}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "applications")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="resource">Resource</label>
    //               <Select
    //                 id="resource"
    //                 placeholder={false}
    //                 aria-label="resource"
    //                 isMulti
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].resource}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "resource")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="permission">Permission</label>
    //               <Select
    //                 id="permission"
    //                 placeholder={false}
    //                 aria-label="permission"
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].permissions}
    //                 // isClearable={true}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "permissions")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <InputLabel htmlFor="operation">Operation</InputLabel>
    //               <Select
    //                 id="operation"
    //                 placeholder={false}
    //                 aria-label="operation"
    //                 options={permissionsList}
    //                 isMulti
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].operations}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "operations")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="domains">Domain</label>
    //               <Select
    //                 id="domains"
    //                 placeholder={false}
    //                 aria-label="domains"
    //                 options={permissionsList}
    //                 isMulti
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].domains}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "domains")
    //                 }
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     })}

    //     <Box>
    //       <DialogActions sx={{ justifyContent: "flex-end" }}>
    //         <button
    //           className="rounded-md job-btn-plain btn"
    //           style={{
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             gap: "0.8rem",
    //             color: "#ffffff",
    //             backgroundColor: "#2e7d32",
    //           }}
    //           onClick={addPolicy}
    //         >
    //           <AddCircleOutlineIcon /> <span>Add Policy</span>
    //         </button>
    //       </DialogActions>
    //     </Box>

    //     <Box sx={{ pb: 2, px: 2, m: 8 }}>
    //       <DialogActions sx={{ justifyContent: "center" }}>
    //         <button
    //           className="rounded-md job-btn btn"
    //           style={{ width: "28%", height: "2.8rem", fontSize: "1.2rem" }}
    //           // fullWidth variant="contained"
    //           onClick={handleSubmit}
    //         >
    //           Submit
    //         </button>
    //       </DialogActions>
    //     </Box>
    //     {openModal && <WarningPopUp />}
    //   </div>
    // </div>

    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen} fullWidth maxWidth="md">
        <DialogContent style={{ width: "900px", height: "auto" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Add New Role To User Mapping
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              E-mail
            </Typography>
            <Select
              style={{ width: "71.5%", margin: "0", padding: "0" }}
              labelId="email"
              id="email"
              size="small"
              // multiple
              value={emailName}
              onChange={handleEmailChange}
              onOpen={() => setSearchUDText("")}
              onClose={() => setSearchUDText("")}
              MenuProps={{
                autoFocus: false,
              }}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              <ListSubheader sx={{ border: "none" }}>
                <TextField
                  style={{ margin: "0 10px", width: "98%" }}
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  // fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchUDText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {displayedUDOptions !== "" &&
                displayedUDOptions
                  ?.sort((a, b) =>
                    a.emailId?.toLowerCase() > b.emailId?.toLowerCase() ? 1 : -1
                  )
                  .map((useData) => (
                    <MenuItem
                      key={useData.id}
                      value={useData.id}
                      name={useData.emailId}
                      // style={getStyles(name, personName, theme)}
                    >
                      {useData.emailId}
                    </MenuItem>
                  ))}
              {displayedUDOptions === "" &&
                userData
                  ?.sort((a, b) =>
                    a.emailId?.toLowerCase() > b.emailId?.toLowerCase() ? 1 : -1
                  )
                  .map((useData) => (
                    <MenuItem
                      key={useData.id}
                      value={useData.id}
                      name={useData.emailId}
                      // style={getStyles(name, personName, theme)}
                    >
                      {useData.emailId}
                    </MenuItem>
                  ))}
            </Select>
            {emailID !== "" && (
              <InfoOutlinedIcon
                style={{ marginLeft: "0.8rem", cursor: "pointer" }}
                onClick={() => setUPDModal(true)}
              />
            )}
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          > */}
          {policyFields.map((policy, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  height: "auto",
                  margin: "20px 0px",
                  padding: "28px 16px",
                  border: "2px solid lightgray",
                  borderRadius: "4px",
                  backgroundColor: "#ffffff",
                  // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  // transition: "0.3s",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "20px 0px",
                  }}
                >
                  <Typography
                    style={{
                      flex: 1,
                      color: "black !important",
                      fontWeight: 600,
                    }}
                  >
                    Mapping {index + 1}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // maxWidth: "335px",
                      // maxHeight: "80px",
                    }}
                  >
                    <CancelIcon onClick={() => removePolicy(index)} />
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "285px",
                      maxHeight: "80px",
                      marginBottom: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label htmlFor="role">Role</label>
                    {/* <Select
                        id="application"
                        placeholder={false}
                        aria-label="application"
                        options={permissionsList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={policyFields[index].role}
                        hideSelectedOptions={false}
                        noOptionsMessage={() => "No options available"}
                        onChange={(event) =>
                          handlePolicyChange(event, index, "role")
                        }
                      /> */}
                    <Select
                      // style={{ width: "71.5%", margin: "0", padding: "0" }}
                      labelId="role"
                      id="role"
                      size="small"
                      // multiple
                      // value={roleName}
                      value={policyFields[index].role}
                      // onChange={handleRoleChange}
                      onChange={(event) =>
                        handlePolicyChange(event, index, "role")
                      }
                      onOpen={() => setSearchRDText("")}
                      onClose={() => setSearchRDText("")}
                      MenuProps={{
                        autoFocus: false,
                        PaperProps: {
                          style: {
                            maxWidth: "200px", // Adjust the max width as needed
                          },
                        },
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
                      // renderValue={(selected) => (
                      //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      //     {selected.map((value) => (
                      //       <Chip key={value} label={value} />
                      //     ))}
                      //   </Box>
                      // )}
                      // MenuProps={MenuProps}
                    >
                      <ListSubheader sx={{ border: "none" }}>
                        <TextField
                          style={{ margin: "0 10px", width: "98%" }}
                          size="small"
                          // Autofocus on textfield
                          autoFocus
                          placeholder="Type to search..."
                          // fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchRDText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {displayedRDOptions !== "" &&
                        displayedRDOptions
                          ?.sort((a, b) =>
                            a.name?.toLowerCase() > b.name?.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((rolData) => (
                            <MenuItem
                              key={rolData.id}
                              value={rolData.id}
                              name={rolData.name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {rolData.name}
                            </MenuItem>
                          ))}
                      {displayedRDOptions === "" &&
                        roleData
                          ?.sort((a, b) =>
                            a.name?.toLowerCase() > b.name?.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((rolData) => (
                            <MenuItem
                              key={rolData.id}
                              value={rolData.id}
                              name={rolData.name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {rolData.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </div>

                  <div
                    style={{
                      maxWidth: "285px",
                      maxHeight: "80px",
                      marginBottom: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label htmlFor="byMaerskModel">By Maersk Model</label>
                    {/* <Select
                      id="byMaerskModel"
                      placeholder={false}
                      aria-label="byMaerskModel"
                      isMulti
                      options={permissionsList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={policyFields[index].byMaerskModel}
                      hideSelectedOptions={false}
                      noOptionsMessage={() => "No options available"}
                      onChange={(event) =>
                        handlePolicyChange(event, index, "byMaerskModel")
                      }
                    /> */}
                    <Select
                      // style={{ width: "71.5%", margin: "0", padding: "0" }}
                      labelId="byMaerskModel"
                      id="byMaerskModel"
                      size="small"
                      multiple
                      value={policyFields[index].byMaerskModel}
                      onChange={(event) =>
                        handlePolicyChange(event, index, "byMaerskModel")
                      }
                      onOpen={() => setSearchODText("")}
                      onClose={() => setSearchODText("")}
                      MenuProps={{
                        autoFocus: false,
                        PaperProps: {
                          style: {
                            maxWidth: "200px", // Adjust the max width as needed
                          },
                        },
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      // MenuProps={MenuProps}
                    >
                      <ListSubheader sx={{ border: "none" }}>
                        <TextField
                          style={{ margin: "0 10px", width: "98%" }}
                          size="small"
                          // Autofocus on textfield
                          autoFocus
                          placeholder="Type to search..."
                          // fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchODText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {displayedODOptions !== "" &&
                        displayedODOptions
                          ?.sort((a, b) =>
                            a.name?.toLowerCase() > b.name?.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((org) => (
                            <MenuItem
                              key={org.fqn}
                              value={org.fqn}
                              name={org.name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {org.name}
                            </MenuItem>
                          ))}
                      {displayedODOptions === "" &&
                        orgModelData
                          ?.sort((a, b) =>
                            a.name?.toLowerCase() > b.name?.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((org) => (
                            <MenuItem
                              key={org.fqn}
                              value={org.fqn}
                              name={org.name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {org.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </div>

                  <div
                    style={{
                      maxWidth: "285px",
                      maxHeight: "80px",
                      marginBottom: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label htmlFor="domains">Domain</label>
                    {/* <Select
                      id="domains"
                      placeholder={false}
                      aria-label="domains"
                      options={permissionsList}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={policyFields[index].domains}
                      hideSelectedOptions={false}
                      noOptionsMessage={() => "No options available"}
                      onChange={(event) =>
                        handlePolicyChange(event, index, "domains")
                      }
                    /> */}
                    <Select
                      // style={{ width: "71.5%", margin: "0", padding: "0" }}
                      labelId="domains"
                      id="domains"
                      size="small"
                      multiple
                      value={policyFields[index].domains}
                      onChange={(event) =>
                        handlePolicyChange(event, index, "domains")
                      }
                      onOpen={() => setSearchDDText("")}
                      onClose={() => setSearchDDText("")}
                      MenuProps={{
                        autoFocus: false,
                        PaperProps: {
                          style: {
                            maxWidth: "200px", // Adjust the max width as needed
                          },
                        },
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      // MenuProps={MenuProps}
                    >
                      <ListSubheader sx={{ border: "none" }}>
                        <TextField
                          style={{ margin: "0 10px", width: "98%" }}
                          size="small"
                          // Autofocus on textfield
                          autoFocus
                          placeholder="Type to search..."
                          // fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchDDText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {displayedDDOptions !== "" &&
                        displayedDDOptions
                          ?.sort((a, b) =>
                            a.name?.toLowerCase() > b.name?.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((dom) => (
                            <MenuItem
                              key={dom.fqn}
                              value={dom.fqn}
                              name={dom.name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {dom.name}
                            </MenuItem>
                          ))}
                      {displayedDDOptions === "" &&
                        domainData
                          ?.sort((a, b) =>
                            a.name?.toLowerCase() > b.name?.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((dom) => (
                            <MenuItem
                              key={dom.fqn}
                              value={dom.fqn}
                              name={dom.name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {dom.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </div>
                </div>
              </div>
            );
          })}
          {/* </div> */}

          <Box>
            <DialogActions sx={{ justifyContent: "flex-end" }}>
              <button
                className="rounded-md job-btn-plain btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.8rem",
                  color: "#ffffff",
                  backgroundColor: "#2e7d32",
                }}
                onClick={addPolicy}
              >
                <AddCircleOutlineIcon /> <span>Add Mapping</span>
              </button>
            </DialogActions>
          </Box>

          {/* {permissionsArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {permissionsArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p"
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>

      {updModal && (
        <UserDetailsModal
          boolean={updModal}
          onOpen={() => setUPDModal(!updModal)}
          email={emailID}
        />
      )}
    </div>
  );
}

export default AddNewRoleToUser;
