import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const [error, setError] = useState();
  const [token, setToken] = useState(null);
  const [DCtoken, setDCToken] = useState(null);
  // const scope = "https://bd384c6c-d925-4099-a56f-83567cdb7ab7/.default";
  const getToken = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const request = {
          scopes: ["eccd4653-2930-4077-be40-8e73fd4855e6/.default"],
          account: account,
        };
        // console.log(instance.acquireTokenSilent);
        const tokenRes = await instance.acquireTokenSilent(request);
        setToken(tokenRes.accessToken);
        console.log(tokenRes.accessToken, "<<=== DC context");
        if (tokenRes && tokenRes.accessToken) {
          localStorage.setItem("token", tokenRes.accessToken); // Store the token in localStorage
          console.log(
            "DC token from localStorage:>",
            localStorage.getItem("token")
          );
        }
      } catch (err) {
        setError(err);
      }
    }
  }, [instance, isAuthenticated, account]);

  const getDatabookToken = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const request = {
          scopes: ["eccd4653-2930-4077-be40-8e73fd4855e6/.default"],
          account: account,
        };
        // console.log(instance.acquireTokenSilent);
        const DCtokenRes = await instance.acquireTokenSilent(request);
        setDCToken(DCtokenRes.accessToken);
        console.log(DCtokenRes.accessToken, "<==== DB context");
      } catch (err) {
        setError(err);
      }
    }
  }, [instance, isAuthenticated, account]);

  return (
    <AuthContext.Provider
      value={{ error, token, getToken, getDatabookToken, DCtoken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
